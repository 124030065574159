/* Adjust the grid container */
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Each card will be at least 250px wide */
    gap: 15px; /* Adjust spacing between cards as needed */
    margin-top: 20px;
}

/* Update the card style for a smaller look */
.card {
    background: #ffffff;
    border: 1px solid #ced2d7;
    border-radius: 5px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
}

.card:hover {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

/* Ensure card images fit nicely */
.card-img {
    width: 100%;
    height: auto;
}

/* Adjust body padding to keep cards compact */
.card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 12px;
}

/* Reduce margins on titles and descriptions */
.card-title {
    color: #2980b9;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 6px;
}

.card-description,
.price {
    font-size: 14px;
    color: #6f7b7c;
    margin: 6px 0;
}

/* Center the Add to Cart button under the price */
.card-links {
    margin-top: auto;
    padding-top: 10px;
    display: flex;
    justify-content: center;
}

.btn {
    display: inline-block;
    padding: 8px 16px;
    background-color: #01733F;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #019d55;
}